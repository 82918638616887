
.apply-coupon
  display flex
  flex-direction row
  flex-wrap nowrap
  align-items center
  gap 14px

form
  display flex
  flex-direction row
  align-items center

.message
  flex-basis 100%
  color red
  font-size 14px

.hide-text
  font-size: 0

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
    margin: auto 0
}
